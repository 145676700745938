import React, { useEffect, Suspense, lazy } from "react";
import { useDispatch } from "react-redux";
import { setSettings } from "./redux/actions/settings";
import Error from "./Pages/Error";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ColorProvider } from "./context/ColorContext";
import Favicon from "./components/Common/Favicon";
import Title from "./components/Common/Title";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "./components/Common/ScrollToTop";
import Loader from "./components/Common/Loader";
import $ from "jquery";

// Lazy load components
const Login = lazy(() => import("./Pages/Login"));
const SignUp = lazy(() => import("./Pages/SignUp"));
const Forget = lazy(() => import("./Pages/Forget"));
const UpdatePassword = lazy(() => import("./Pages/UpdatePassword"));
const Otp = lazy(() => import("./Pages/Otp"));
const Test = lazy(() => import("./Pages/Test"));
const Dashboard = lazy(() => import("./Pages/Dashboard"));
const AddMember = lazy(() => import("./Pages/AddMember"));
const Settings = lazy(() => import("./Pages/Settings"));
const PersonalSetting = lazy(() => import("./Pages/PersonalSetting"));
const User = lazy(() => import("./Pages/User"));
const Wallet = lazy(() => import("./Pages/Wallet"));
const UserInfo = lazy(() => import("./Pages/UserInfo"));
const EditUser = lazy(() => import("./Pages/EditUser"));
const Entries = lazy(() => import("./Pages/Entries"));
const GroupCoaches = lazy(() => import("./Pages/GroupCoaches"));
const GroupList = lazy(() => import("./Pages/GroupList"));
const GrouplistLevel = lazy(() => import("./Pages/GrouplistLevel"));
const Suppliers = lazy(() => import("./Pages/Suppliers"));
const Events = lazy(() => import("./Pages/Events"));
const EventsSummary = lazy(() => import("./Pages/EventsSummary"));
const EventcardImage = lazy(() => import("./Pages/EventcardImage"));
const EventTable = lazy(() => import("./Pages/EventTable"));
const TitleeventMember = lazy(() => import("./Pages/TitleeventMember"));
const BalancesheetDetails = lazy(() => import("./Pages/BalancesheetDetails"));
const BalanceSheet = lazy(() => import("./Pages/BalanceSheet"));
const MoneyMovements = lazy(() => import("./Pages/MoneyMovements"));
const EventDetails = lazy(() => import("./Pages/EventDetails"));
const Token = lazy(() => import("./Pages/Token"));
const Chat = lazy(() => import("./Pages/Chat"));
const EventUpdate = lazy(() => import("./Pages/EventUpdate"));
const SupplierUpdate = lazy(() => import("./Pages/SupplierUpdate"));
const GroupDistribution = lazy(() => import("./Pages/GroupDistribution"));
const MoneyMomentDetail = lazy(() => import("./Pages/MoneyMomentDetail"));
const Email = lazy(() => import("./Pages/Email"));
const SupplierDetail = lazy(() => import("./Pages/SupplierDetail"));
const PublicSummery = lazy(() => import("./components/events/PublicSummery"));
const AccessControlGate = lazy(() => import("./Pages/AccessControlGate"));
const AccessControlLogs = lazy(() => import("./Pages/AccessControlLogs"));
const AccessControlRules = lazy(() => import("./Pages/AccessControlRules"));
const AccessModules = lazy(() => import("./Pages/AccessModules"));

function App() {
  const dispatch = useDispatch();

  const initializeTooltips = () => {
    $(document).on("mouseenter", "[data-tooltip]", function () {
      // Show the tooltip when hovering over the element
      const tooltipText = $(this).attr("data-tooltip");
      const tooltipElement = $(
        `<div class="custom-tooltip">${tooltipText}</div>`
      );

      // Append the tooltip to the body and position it
      $("body").append(tooltipElement);
      const offset = $(this).offset();
      tooltipElement.css({
        top: offset.top - tooltipElement.outerHeight() - 10, // Position above the element
        left:
          offset.left +
          $(this).outerWidth() / 2 -
          tooltipElement.outerWidth() / 2,
      });

      // Remove the tooltip when leaving the element
      $(this).on("mouseleave", function () {
        tooltipElement.remove();
      });
    });
  };

  useEffect(() => {
    initializeTooltips(); // Initialize tooltips on component mount

    // Cleanup tooltips event on component unmount
    return () => {
      $(document).off("mouseenter", "[data-tooltip]");
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/admin/admin/getAllSettings`;

      try {
        const response = await fetch(url);
        const data = await response.json();

        if (data.status === "success") {
          dispatch(setSettings(data.data));
        } else {
          console.error("Failed to load settings:", data.message);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <ColorProvider>
      <Favicon />
      <Title />
      <Router>
        <ScrollToTop>
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route exact path="/" element={<Login />} />
              <Route exact path="/signup" element={<SignUp />} />
              <Route exact path="/acs/rules" element={<AccessControlRules />} />
              <Route exact path="/acs/logs" element={<AccessControlLogs />} />
              <Route exact path="/acs/gate" element={<AccessControlGate />} />
              <Route exact path="/accessModules" element={<AccessModules />} />
              <Route exact path="/add-member" element={<AddMember />} />
              <Route exact path="/forget-password" element={<Forget />} />
              <Route
                exact
                path="/update-password"
                element={<UpdatePassword />}
              />
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route exact path="/verify-otp" element={<Otp />} />
              <Route exact path="/test" element={<Test />} />
              <Route
                exact
                path="/personal-info"
                element={<PersonalSetting />}
              />
              <Route
                exact
                path="/money-movements"
                element={<MoneyMovements />}
              />
              <Route
                exact
                path="/money-movements/:id"
                element={<MoneyMomentDetail />}
              />
              <Route exact path="/settings" element={<Settings />} />
              <Route exact path="/wallet" element={<Wallet />} />
              <Route exact path="/user" element={<User />} />
              <Route exact path="/userinfo" element={<UserInfo />} />
              <Route exact path="/event/summary" element={<EventsSummary />} />
              <Route exact path="/events" element={<EventcardImage />} />
              <Route exact path="/edituser/:id" element={<EditUser />} />
              <Route exact path="/suppliers" element={<Suppliers />} />
              <Route exact path="/supplier/:id" element={<SupplierUpdate />} />
              <Route
                exact
                path="/supplier/money-moment/:id"
                element={<SupplierDetail />}
              />
              <Route
                exact
                path="/eventmembers/:id"
                element={<TitleeventMember />}
              />
              <Route
                exact
                path="/eventbalance/:id"
                element={<BalancesheetDetails />}
              />
              <Route exact path="/event/:id" element={<EventDetails />} />
              <Route
                exact
                path="/public/event/:id"
                element={<PublicSummery />}
              />
              <Route exact path="/balance-sheet" element={<BalanceSheet />} />
              <Route exact path="/update-event/:id" element={<EventUpdate />} />
              <Route exact path="/event/view" element={<EventTable />} />
              <Route exact path="/group-list/:id" element={<GroupList />} />
              <Route exact path="/chat" element={<Chat />} />
              <Route
                exact
                path="/group-list-level/:id"
                element={<GrouplistLevel />}
              />
              <Route exact path="/group-coaches" element={<GroupCoaches />} />
              <Route
                exact
                path="/group-distribution"
                element={<GroupDistribution />}
              />
              <Route exact path="/entries-exits" element={<Entries />} />
              <Route exact path="/event/create" element={<Events />} />
              <Route exact path="/token/:token" element={<Token />} />
              <Route exact path="/email" element={<Email />} />
              <Route exact path="*" element={<Error />} />
            </Routes>
          </Suspense>
        </ScrollToTop>
      </Router>
      <ToastContainer />
    </ColorProvider>
  );
}

export default App;
